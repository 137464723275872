<template>
  <el-empty image-size="300" description="请去往企业微信BD工作台使用"></el-empty>
</template>

<script>
export default {

}
</script>

<style>

</style>